
body {
    font-family: "Poppins", sans-serif;
    background-color: #fff;
    color: #921918;
    font-size: 1.25rem;
}

.page-container {
    max-width: 1050px;
}

.page-container-smaller {
    max-width: 815px;
}

.header-background {
    /*background-color: #181819;*/
    padding-top: 40px;
    padding-bottom: 20px;
}

.title {
    font-size: 80px;
    font-weight: bold;
    color: #000;
}

.subtitle {
    font-size: 40px;
}

.grey {
    color: #929292;
}

.button {
    font-size: 12px;
    background-color: #ee4666;
    color: #fff;
    min-width: 120px;
    text-transform: uppercase;
    border-radius: 0px;
    border: none;
    padding: 10px 12px;
    font-weight: 600;
    cursor: pointer;
    transition: all .3s ease;
}

.button:hover {
    background-color: #c41334;
    color: #fff;
}

.button:disabled {
    background-color: lightgray;
    color: white;
    cursor: default;
}

a {
    color: #484848;
    text-decoration: none;
}

a:hover {
    color: #ee4666;
}

.description {
    color: rgb(53, 53, 53);
}

.form-control,
.form-select {
    border: 1px solid black;
    border-radius: 0px;
}

.text-container {
    background-color: #181819;
    color: #fff;
    padding: 20px 12px;
    font-size: 26px;
    text-transform: uppercase;
}

.cursor-pointer {
    cursor: pointer;
}

.delivery_icon {
    max-width: 55px;
    vertical-align: top;
    margin-top: 4px;
    padding-left: 17px;
    padding-right: 17px;
}

.delivery-description {
    margin-left: 55px !important;
}

.payment_icon {
    max-width: 60px;
    vertical-align: top;
    padding-right: 5px;
}

.payment-description {
    margin-left: 60px !important;
}

/*checkboxes and radio-buttons*/

input[type="checkbox"],
input[type="radio"] {
    margin-top: 6px;
}

.form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}

.payment-names {
    width: 100%;
}

/* Accordions - arrow */
.accordion-button::after {
    display: none; /* hide bootstrap arrow */
}

.accordion-arrow {
    margin-left: 5px;
    transition: transform 0.3s ease;
}

@media (max-width: 768px) {
    .accordion-arrow {
        min-height: 24px;
        min-width: 24px;
    }
}

.accordion-button.collapsed .accordion-arrow {
    transform: rotate(0deg); /* Right arrow */
}

.accordion-button:not(.collapsed) .accordion-arrow {
    transform: rotate(-180deg); /* Down arrow */
}