
.footer-background {
    background-color: #f8f9fa;
}

footer {
    margin-top: 100px;
    color: #484848;
}

.footer-title {
    margin-bottom: 20px;
    font-weight: 600;
}

footer .grey {
    color:#777777;
}

.f-14 {
    font-size: 14px;
}

.list-unstyled li {
    line-height: 32px;
}

.copyright {
    font-size: 13px;
    background-color: #eff1f4;
    min-height: 80px;
    padding: 30px 0;
}